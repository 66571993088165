import React from 'react'
import Layout from 'components/Layout'
import TermsOfUse from 'components/TermsOfUse'

const EmbeddedTermsOfUsePage = () => {
  return (
    <Layout onlyPage>
      <TermsOfUse />
    </Layout>
  )
}

export default EmbeddedTermsOfUsePage
